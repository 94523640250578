<!-- eslint-disable max-len -->
<template>
  <div class="relative h-full">
    <div class="modal-backdrop" v-if="openModal">
      <div class="modal-dialog" tabindex="-1">
        <Form @submit="createNewReport" ref="newReportForm" class="flex flex-col flex-grow">
          <div class="modal-header items-center">
            <div class="flex-grow">
              <h2 class="font-bold text-xl">
                Novo relatório
              </h2>
            </div>
            <div class="flex-shrink-0">
              <Button aria-label="Close" kind="plain" @click="toggleOpenModal">
                <span class="sr-only">Fechar modal</span>
                <XIcon size="24" />
              </Button>
            </div>
          </div>
          <div class="modal-body flex-grow">
            <div class="mb-5">
              <label class="form-label">Tipo</label>
              <Field required :error-message="newReportTypeErrorMessage">
                <Select v-model="newReportType" placeholder="Selecionar" full-width dropdown>
                  <Option v-for="reportType, index in reportTypes" :key="index" :value="reportType.value">
                    {{ reportType.text }}
                  </Option>
                </Select>
              </Field>
            </div>
            <div>
              <label class="form-label">Período</label>
              <Field
                :required="(value) => value && value.length > 0"
                :error-message="newReportDatesErrorMessage"
              >
                <DatePicker
                  v-model="newReportDates"
                  :maxDate="maxDate"
                  placeholder="Selecionar"
                  mode="range"
                  full-width
                  icon
                  dropdown
                />
              </Field>
            </div>
          </div>
          <div class="modal-footer">
            <div class="flex gap-3">
              <Button type="submit" class="w-full" kind="primary">
                Gerar relatório
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>

    <Sidenav
      @sidenavOpen="handleSidenavOpen"
      @onChangeRetailer="handleChangeRetailer"
      :opened="sidenavOpen"
    />

    <div class="relative lg:ml-64 flex flex-col min-h-full">
      <Topbar @sidenavOpen="handleSidenavOpen" pageName="Relatórios" />

      <header class="border-b-2 border-gray-200 bg-white">
        <div class="flex flex-col lg:flex-row gap-4 lg:gap-5 px-4 lg:px-6 py-3 lg:pb-5">
          <div class="flex flex-grow gap-3 items-center">
            <div class="flex-shrink-0 w-btn h-btn flex items-center justify-center rounded-xl bg-primary-100 text-primary-600">
              <DocumentReportIcon size="24" />
            </div>
            <h1 class="font-bold text-xl">Relatórios</h1>
          </div>
          <div class="flex-shrink-0 hidden lg:block">
            <Button class="w-full" kind="primary" @click="toggleOpenModal">
              <PlusIcon size="24" />
              <span>Novo relatório</span>
            </Button>
          </div>
        </div>
      </header>

      <Alert v-show="showAlert" @close="closeAlert" kind="info">
        Enviaremos uma notificação por email quando o relatório estiver pronto para baixar.
      </Alert>

      <main class="relative lg:px-6 flex-grow overflow-x-auto pt-5">
        <div
          class="bg-white bg-opacity-90 absolute top-0 right-0 left-0 bottom-0 z-10 flex flex-col items-center pt-36"
          :class="{ 'hidden' : !searchingReports }"
        >
          <svg role="status" class="w-12 h-12 mr-2 text-gray-300 animate-spinner" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#20627A"></path>
          </svg>
        </div>
        <Table
          :headers="tableHeaders"
          v-model="currentSort"
          @sort="fillReports"
          class="table-compact"
        >
          <tr v-for="report, index in reports" :key="index">
            <td class="whitespace-nowrap">{{ getReportTypeText(report.layout) }}</td>
            <td>{{ getReportPeriod(report.startDate, report.endDate) }}</td>
            <td>
              <div>{{ report.createdAt | dateFilter }}</div>
              <div class="text-sm text-gray-500 leading-5">
                {{ report.createdAt | hourFilter }}
              </div>
            </td>
            <td>
              <div class="flex justify-end items-center">
                <Button
                  v-if="report.status === 'DOWNLOAD'"
                  @click="() => downloadFile(report)"
                  kind="primary-subtle"
                  shape="pill"
                  size="small"
                >
                  <DownloadIcon size="20" />
                  <span>{{ reportStatus[report.status] }}</span>
                </Button>
                <Button
                  v-else-if="report.status === 'PROCESSING'"
                  kind="loading"
                  shape="pill"
                  size="small"
                >
                  <span>{{ reportStatus[report.status] || reportStatus.default }}</span>
                </Button>
                <span v-else class="py-2">
                  {{ reportStatus[report.status] || reportStatus.default }}
                </span>
              </div>
            </td>
          </tr>
        </Table>
      </main>
      <footer class="bg-white lg:sticky lg:bottom-0 z-10 lg:px-6">
        <Pagination
          v-model="currentPage"
          :itemsPerPage="reportsPerPage"
          :numberOfItems="totalOfReports"
          @pagination="fillReports"
        />
      </footer>
    </div>

    <div class="sticky left-0 bottom-0 z-10 border-t lg:hidden bg-white py-4 px-6">
      <Button class="w-full" kind="primary" @click="toggleOpenModal">
        <PlusIcon size="24" />
        <span>Novo relatório</span>
      </Button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-confusing-arrow */
import { mapActions, mapMutations, mapState } from 'vuex';
import { PlusIcon, DownloadIcon, DocumentReportIcon } from '@vue-hero-icons/outline';
import { XIcon } from '@vue-hero-icons/solid';
import FileSaver from 'file-saver';
import iconv from 'iconv-lite';
import moment from 'moment-timezone';

import {
  Alert,
  Button,
  DatePicker,
  Field,
  Form,
  Option,
  Pagination,
  Select,
  Table,
} from '@/components';
import StringMixin from '@/mixins/string';
import Sidenav from '../../Sidenav.vue';
import Topbar from '../../Topbar.vue';
import ReportsService from '../reports.service';

moment.locale('pt-BR');
moment.tz('America/Sao_Paulo');

export default {
  name: 'reports',
  metaInfo: {
    title: 'Relatórios',
  },
  components: {
    Alert,
    Button,
    DatePicker,
    DownloadIcon,
    DocumentReportIcon,
    Field,
    Form,
    Option,
    Pagination,
    PlusIcon,
    Select,
    Sidenav,
    Table,
    Topbar,
    XIcon,
  },
  mixins: [
    StringMixin,
  ],
  data: () => ({
    canShowList: false,
    maxDate: moment().startOf('day').toDate(),
    newReportType: null,
    newReportTypeErrorMessage: 'Selecione um tipo',
    newReportDates: [],
    newReportDatesErrorMessage: 'Selecione uma data',
    openModal: false,
    searchingReports: false,
    sidenavOpen: false,
    reportStatus: {
      default: 'Não foi possível verificar',
      DOWNLOAD: 'Baixar',
      ERROR: 'Erro',
      PROCESSING: 'Processando',
    },
    tableHeaders: [
      { text: 'Relatório' },
      { text: 'Período' },
      {
        text: 'Solicitado em',
        sortable: true,
        sortFunctions: {
          ascending: { createdAt: 1 },
          descending: { createdAt: -1 },
        },
      },
      {
        text: 'Status',
        srOnly: true,
      },
    ],
    reportsPerPage: 15,
    currentPage: 0,
    currentSort: {
      headerIndex: 2,
      direction: 'descending',
    },
    defaultSort: {
      headerIndex: 2,
      direction: 'descending',
    },
    showAlert: false,
  }),
  computed: {
    ...mapState('reports', ['reports', 'reportTypes', 'totalOfReports']),
    ...mapState('retailer', ['currentRetailer']),
  },
  methods: {
    ...mapActions('loading', ['setIsLoading']),
    ...mapActions('reports', ['generateReport', 'getReports']),
    ...mapActions('retailer', ['getRetailers']),
    ...mapMutations('reports', ['setCurrentPage', 'setCurrentSort']),
    closeAlert() {
      this.showAlert = false;
    },
    handleSidenavOpen() {
      this.sidenavOpen = !this.sidenavOpen;
    },
    toggleOpenModal() {
      this.openModal = !this.openModal;
      if (!this.openModal) {
        this.newReportType = null;
        this.newReportDates = [];
        this.$refs.newReportForm.reset();
      }
    },
    getReportTypeText(layout) {
      return this.reportTypes.find(({ value }) => layout.includes(value)).text;
    },
    getLayout() {
      const customReport = {
        Zoom: {
          name: 'Zoom',
          type: ['transactions', 'finance'],
        },
        'Casa & Video': {
          name: 'CasaEVideo',
          type: ['finance'],
        },
        'LE BISCUIT S.A': {
          name: 'CasaEVideo',
          type: ['finance'],
        },
        'Le Biscuit': {
          name: 'CasaEVideo',
          type: ['finance'],
        },
      };

      const layout = this.newReportType;
      const customReportInfo = customReport[this.currentRetailer.name];
      if (!customReportInfo || !customReportInfo.type.includes(this.newReportType)) {
        return `${layout}Standard`;
      }
      return `${layout}${customReportInfo.name}`;
    },
    async createNewReport() {
      const [startDate, endDate] = this.newReportDates;
      this.generateReport({
        layout: this.getLayout(),
        retailerId: this.currentRetailer._id,
        body: {
          startDate: moment(startDate).startOf('day').format(),
          endDate: moment(endDate).endOf('day').format(),
          format: 'csv',
          intraUser: localStorage.getItem('userId'),
        },
      });
      this.showAlert = true;
      this.currentPage = 0;
      this.currentSort = this.defaultSort;
      this.toggleOpenModal();
      this.fillReports();
    },
    async handleChangeRetailer() {
      await this.fillReports({ changePagination: true });
      this.canShowList = this.reports.length !== 0;
    },
    async downloadFile(report) {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const system = ['windows', 'macintosh', 'linux']
        .find((osName) => userAgent.includes(osName));
      const typeEnum = {
        windows: 'win',
        macintosh: 'mac',
        linux: 'win',
      };
      const type = typeEnum[system];
      if (!type) return;

      const { s3Link } = report;
      const parsedLink = decodeURIComponent(s3Link);
      const separator = '.com/';
      const path = parsedLink.substring(parsedLink.indexOf(separator) + separator.length);
      const filename = parsedLink.substring(parsedLink.lastIndexOf('/') + 1);
      try {
        const response = await ReportsService.downloadFromS3(path);
        if (type === 'mac') {
          const macBuf = iconv.encode(Buffer.from(response), 'macintosh');
          FileSaver.saveAs(new Blob([macBuf]), `mac_${filename}`, {
            type: 'text/csv;charset=macintosh',
          });
        } else {
          FileSaver.saveAs(new Blob([response]), `win_${filename}`, {
            type: 'text/csv;charset=utf-8',
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    getReportPeriod(startDate, endDate) {
      const start = moment(startDate).format('DD/MM/YYYY');
      const end = moment(endDate).format('DD/MM/YYYY');
      return `${start} até ${end}`;
    },
    translateStatus(status) {
      const translator = {
        PROCESSING: 'loading',
        DOWNLOAD: 'Download',
        ERROR: 'Erro',
      };
      return translator[status] || 'Não foi possível verificar';
    },

    async fillReports() {
      this.searchingReports = true;
      const { headerIndex, direction } = this.currentSort;
      const sortFunction = this.tableHeaders[headerIndex].sortFunctions[direction];
      await this.getReports({
        limit: this.reportsPerPage,
        offset: this.currentPage,
        sort: JSON.stringify(sortFunction),
      });
      this.searchingReports = false;
    },
  },
  async mounted() {
    await this.setIsLoading({ isLoading: true });
    if (!this.currentRetailer) await this.getRetailers();
    await this.fillReports();
    await this.setIsLoading({ isLoading: false });
  },
};
</script>
